const FACEBOOK_VERSION_API = process.env.REACT_APP_FACEBOOK_VERSION_API;
const BASE_API = process.env.REACT_APP_ROUTE_API;

const token = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem('user'));

export default {
  refreshToken: async (data) => {
    const dataToken = { user_id: user.id, sn_name: data.graphDomain, sn_user_id: data.id, access_token: data.accessToken, expires: data.expiresIn, token: token }
    try {
      const req = await fetch(`${BASE_API}user-social-network`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToken)
      });
      const json = await req.json();
      return json;      

    } catch (error) {
      console.log(error);
    }
  },
  saveSocialNetwork: async (dataSocialNetwork) => {
    try {
      dataSocialNetwork.token = token;
      dataSocialNetwork.user_id = user.id;
      const req = await fetch(`${BASE_API}user-social-profile`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataSocialNetwork)
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  },
  getSocialNetworks: async(access_token, valid_token) => {
    try {
      const req = await (await fetch(`${BASE_API}user-social-profile/` + user.id + `?token=` + token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      })).json()
      
      if(valid_token){
        const data = await Promise.all(
          req.map(async (item) => {
            const facebookData = await (await fetch(`https://graph.facebook.com/${FACEBOOK_VERSION_API}/${item.social_profile_id}?fields=biography,followers_count,name,username,profile_picture_url&access_token=${access_token}`, {
              method: "GET",
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
            })).json();
  
            // Adiciona a propriedade social_profile_link a cada item
            facebookData.social_profile_link = item.social_profile_username;
            return facebookData;
          })
        );       
        return data
      }
      
      return req;

    } catch (error) {
      console.log(error);
    }
  },
  getNetworkToken: async () => {
    try {
      const req = await fetch(`${BASE_API}user-social-network/` + user.id + `?token=` + token, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      })

      return req.json();

    } catch (error) {
      console.log(error);
    }
  },
  deleteProfile: async (social_profile_id) => {
    try {
      const req = await fetch(`${BASE_API}user-social-profile`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: user.id, social_profile_id:social_profile_id, token:token})
      });
      const json = await req.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  }
};